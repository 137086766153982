import ResetKycKybButton from 'components/common/molecules/ResetKycKybButton';
import AccountsList from 'components/common/templates/lists/AccountsList';
import BeneficiariesList from 'components/common/templates/lists/BeneficiariesList';
import KycKybAudit from 'components/common/templates/lists/ComplianceTab/KycKybAuditList';
import KycKybLevelSubTab from 'components/common/templates/lists/ComplianceTab/KycKybLevel';
import MemberRiskDetails from 'components/common/templates/lists/ComplianceTab/RiskDetailsSubTab/MemberRiskDetails';
import MarketingPreferencesList from 'components/common/templates/lists/MarketingPreferencesList';
import OutgoingTransfer from 'components/common/templates/lists/OutgoingTransfer';
import StatusHistoryList from 'components/common/templates/lists/StatusHistoryList';
import TopupCardsList from 'components/common/templates/lists/TopupCardsList';
import TransactionsList from 'components/common/templates/lists/TransactionsList';
import MemberCards from 'components/pages/authorised/MemberDetails/Tabs/MemberCards';
import MemberSettings from 'components/pages/authorised/MemberDetails/Tabs/Settings';
import {
    accountsRouteSegmentPath,
    beneficiariesRouteSegmentPath,
    cardsRouteSegmentPath,
    changeStatusRouteSegmentPath,
    complianceRouteSegmentPath,
    dueDiligenceKycRouteSegmentPath,
    kycLevelRouteSegmentPath,
    marketingPreferencesRouteSegmentPath,
    pendingTransactionsRouteSegmentPath,
    riskAssessmentsRouteSegmentPath,
    statusHistoryRouteSegmentPath,
    statusManagementRouteSegmentPath,
    topupCardsRouteSegmentPath,
    transactionsRouteSegmentPath,
    withdrawRouteSegmentPath,
} from 'config/routes';
import { AccountOwnerTypes } from 'constants/AccountModel';
import { KycKybEntityTypes } from 'constants/KycKybModel';
import { PN } from 'constants/PermissionsModel';
import { ProductTypes } from 'constants/ProductModel';
import { MemberActionChecks } from 'hooks/useMemberActionChecks';
import { LocationPathname } from 'models/app/navigation';
import { AccessControlContent } from 'models/domain/accessControl';
import { clearMemberStatusHistoryList, fetchMemberStatusHistoryList } from 'redux/member-status/actions';
import { memberStatusHistoryListsPerLocationStoreKeyName, memberStatusReducerName } from 'redux/member-status/reducer';

import {
    CheckCircleOutlined,
    CreditCardOutlined,
    SettingOutlined,
    TransactionOutlined,
    WalletOutlined,
    UserOutlined,
    ShopOutlined,
    DollarOutlined,
} from '@ant-design/icons';
import { MemberStatuses, MemberDetails, SharedEntityStatuses } from '@manigo/manigo-domain-typings';
import React from 'react';


import createMemberStatusHistoryListColumns from './Tabs/StatusHistory/tableColumns';
import UpdateMemberStatusForm from './Tabs/UpdateMemberStatus';


const createMemberComplianceSubTabsConfig = ({
    accessControl,
    isInReadOnlyMode,
    entityDetails,
    isLoadingDetails,
    memberId,
    parentActiveMainTabSegmentURL,
    baseUrlWithoutTabsSlugs,
    t,
}) => {
    const kybKycType = 'KYC';

    return [
        ...(accessControl.hasPermission(PN.RISK_ASSESSMENT.GET_DATA)
            ? [{
                label: t('member:memberDetails.tabs.compliance.subHeadings.riskScoreAndAssessment'),
                isDefault: true,
                activeSubTabSegmentURL: riskAssessmentsRouteSegmentPath,
                parentActiveMainTabSegmentURL,
                content: (
                    <MemberRiskDetails
                        entityData={entityDetails}
                    />),
            }]
            : []),


        ...(accessControl.hasPermission(PN.KYCKYB.GET_LEVEL) && accessControl.isKycKybApprovalEnabled()
            ? [{
                label: t('common:tabsNames.kycKybManagement', { kybKycType, entityType: 'Members' }),
                activeSubTabSegmentURL: kycLevelRouteSegmentPath,
                parentActiveMainTabSegmentURL,
                content: (
                    <KycKybLevelSubTab
                        entityType={KycKybEntityTypes.MEMBER}
                        entityData={entityDetails}
                        entityId={memberId}
                        isInReadOnlyMode={[SharedEntityStatuses.CLOSED, SharedEntityStatuses.PENDING_CLOSURE].includes(entityDetails.memberStatus)}
                    >
                        <ResetKycKybButton
                            memberId={entityDetails.memberId}
                            entityName={`${entityDetails.memberFirstName} ${entityDetails.memberLastName}`}
                            isInReadOnlyMode={isInReadOnlyMode && ![MemberStatuses.ACTIVE, MemberStatuses.KYC_REJECTED].includes(entityDetails.memberStatus)}
                            baseUrlWithoutTabsSlugs={baseUrlWithoutTabsSlugs}
                            isEntityLoading={isLoadingDetails}
                            gutterTop
                        />
                    </KycKybLevelSubTab>),
            }] : []),

        ...(accessControl.hasPermission(PN.KYCKYB.GET_LOGS)
    && accessControl.isKycKybApprovalEnabled()
            ? [
                {
                    label: t('common:tabsNames.kycKybHistory', { kybKycType, entityType: 'Members' }),
                    activeSubTabSegmentURL: dueDiligenceKycRouteSegmentPath,
                    parentActiveMainTabSegmentURL,
                    content: <KycKybAudit memberId={memberId} />,
                },
            ]
            : []),

        ...(accessControl.hasPermission(PN.TRANSACTIONS.AUDIT_PAYMENT)
            ? [
                {
                    label: t('common:tabsNames.pendingTransactions'),
                    hasList: true,
                    activeSubTabSegmentURL: pendingTransactionsRouteSegmentPath,
                    parentActiveMainTabSegmentURL,
                    content: (
                        <TransactionsList
                            memberId={memberId}
                            enforcePendingOnly
                        />
                    ),
                },
            ]
            : []),
    ];
};

const createStatusManagementSubTabsConfig = ({
    accessControl,
    isLoadingDetails,
    entityDetails,
    isInReadOnlyMode,
    parentActiveMainTabSegmentURL,
    memberStatusActionChecks,
    t,
}:{
  accessControl: AccessControlContent,
  isLoadingDetails: boolean,
  entityDetails: MemberDetails,
  isInReadOnlyMode: boolean,
  parentActiveMainTabSegmentURL: string,
  memberStatusActionChecks: MemberActionChecks,
  t: any
}) => [
    ...(Object.keys(memberStatusActionChecks).some((key) => memberStatusActionChecks[key])
  && entityDetails.memberStatus !== SharedEntityStatuses.CLOSED
        ? [{
            label: t('common:tabsNames.changeStatus'),
            isDefault: true,
            activeSubTabSegmentURL: changeStatusRouteSegmentPath,
            parentActiveMainTabSegmentURL,
            content: <UpdateMemberStatusForm
                isLoadingDetails={isLoadingDetails}
                memberDetails={entityDetails}
                isInReadOnlyMode={isInReadOnlyMode}
            />,
        }]
        : []
    ),

    ...(accessControl.hasPermission(PN.MEMBERS.READ_STATUS_HISTORY)
        ? [{
            label: t('common:tabsNames.statusHistory'),
            activeSubTabSegmentURL: statusHistoryRouteSegmentPath,
            parentActiveMainTabSegmentURL,
            content: <StatusHistoryList
                reducerName={memberStatusReducerName}
                listsByLocationStoreKeyName={memberStatusHistoryListsPerLocationStoreKeyName}
                fetcherListAction={fetchMemberStatusHistoryList}
                clearListAction={clearMemberStatusHistoryList}
                contextEnforcedQueryParams={{ memberId: entityDetails.memberId }}
                createTableColumns={createMemberStatusHistoryListColumns}
            />,
        }]
        : []),
];
// TODO reduce complexity
// eslint-disable-next-line complexity
export const createMemberDetailsTabsConfig = ({
    fixedId,
    isInReadOnlyMode,
    isLoadingDetails,
    entityDetails,
    dispatchOpenAddAccountModal,
    accessControl,
    returnPath, // TODO rename
    currentLocation,
    baseUrlWithoutTabsSlugs,
    memberStatusActionChecks,
    t,
}: {
  fixedId: number,
  isInReadOnlyMode: boolean,
  isLoadingDetails: boolean,
  entityDetails: MemberDetails,
  dispatchOpenAddAccountModal: any,
  accessControl: AccessControlContent,
  returnPath: string,
  currentLocation: any,
  baseUrlWithoutTabsSlugs: LocationPathname,
  memberStatusActionChecks: MemberActionChecks,
  t: any
}) => [
    ...(accessControl.hasPermission(PN.ACCOUNTS.READ)
        ? [
            {
                icon: <WalletOutlined />,
                label: t('common:tabsNames.accounts'),
                hasList: true,
                activeMainTabSegmentURL: accountsRouteSegmentPath,
                isDefault: true,
                content: (
                    <AccountsList
                        ownerType={AccountOwnerTypes.MEMBER}
                        ownerId={fixedId}
                        isInReadOnlyMode={isInReadOnlyMode}
                        onAddAccount={
                            accessControl.hasPermission(PN.ACCOUNTS.CREATE)
            && !isInReadOnlyMode
                                ? () => dispatchOpenAddAccountModal({
                                    title: t('accounts:modals.addAccount.titles.member'),
                                    clientId: entityDetails?.clientId,
                                    memberId: fixedId,
                                    allowedAccountTypes: [ProductTypes.Personal],
                                    setName: false,
                                }, currentLocation)
                                : undefined
                        }
                    />),
            }] : []),
    ...(accessControl.hasPermission(PN.CARDS.READ_BY_MEMBER)
        ? [
            {
                icon: <CreditCardOutlined />,
                label: t('common:tabsNames.cards'),
                hasList: true,
                activeMainTabSegmentURL: cardsRouteSegmentPath,
                content: (
                    <MemberCards
                        memberId={fixedId}
                        memberFirstName={entityDetails.memberFirstName}
                        memberLastName={entityDetails.memberLastName}
                        clientId={entityDetails?.clientId}
                        isInReadOnlyMode={isInReadOnlyMode}
                    />
                ),
            }] : []),

    ...(accessControl.hasPermission(PN.TRANSACTIONS.READ_ALL)
        ? [
            {
                icon: <TransactionOutlined />,
                label: t('common:tabsNames.transactions'),
                hasList: true,
                activeMainTabSegmentURL: transactionsRouteSegmentPath,
                content: (
                    <TransactionsList
                        key="member"
                        memberId={fixedId}
                    />),
            }] : []),

    {
        icon: <SettingOutlined />,
        label: t('common:tabsNames.settings'),
        activeMainTabSegmentURL: 'settings',
        content: (
            <MemberSettings
                memberId={fixedId}
                clientId={entityDetails?.clientId}
                isInReadOnlyMode={isInReadOnlyMode}
            />
        ),
    },

    ...(accessControl.clientConfig?.generalSettings?.beneficiariesEnabled
    && accessControl.hasPermission(PN.BENEFICIARIES.SEARCH)
        ? [{
            icon: <UserOutlined />,
            label: t('common:tabsNames.beneficiaries'),
            activeMainTabSegmentURL: beneficiariesRouteSegmentPath,
            hasList: true,
            content: (
                <BeneficiariesList ownerContext={{
                    memberId: fixedId,
                    isInReadOnlyMode,
                    ownerName: `${entityDetails.memberFirstName} ${entityDetails.memberLastName} (Member)`,
                    returnPath,
                }}
                />
            ),
        }] : []),
    ...(accessControl.hasOneOfPermissions([
        PN.TRANSACTIONS.MAKE_PAYMENT,
        PN.ACCOUNTS.READ,
        PN.BENEFICIARIES.GET_ALL,
    ])
        ? [
            {
                icon: <DollarOutlined />,
                label: t('common:tabsNames.withdraw'),
                activeMainTabSegmentURL: withdrawRouteSegmentPath,
                content: <OutgoingTransfer memberId={fixedId} />,
            },
        ]
        : []),

    ...(accessControl.hasOneOfPermissions([
        PN.KYCKYB.GET_LOGS,
        PN.KYCKYB.GET_LEVEL,
        PN.MEMBERS.GET_RISK_DETAILS,
        PN.TRANSACTIONS.AUDIT_PAYMENT,
    ])
        ? [{
            icon: <CheckCircleOutlined />,
            label: t('common:tabsNames.compliance'),
            activeMainTabSegmentURL: complianceRouteSegmentPath,
            // XXX no 'content' but 'subTabsConfig'
            subTabsConfig: createMemberComplianceSubTabsConfig({
                accessControl,
                entityDetails,
                isInReadOnlyMode,
                isLoadingDetails,
                memberId: fixedId,
                baseUrlWithoutTabsSlugs,
                t,
                parentActiveMainTabSegmentURL: complianceRouteSegmentPath,
            }),
        }]
        : []),

    ...((Object.keys(memberStatusActionChecks).some((key) => memberStatusActionChecks[key])
  || accessControl.hasPermission(PN.MEMBERS.READ_STATUS_HISTORY))
        ? [{
            icon: <CheckCircleOutlined />,
            label: t('common:tabsNames.statusManagement'),
            activeMainTabSegmentURL: statusManagementRouteSegmentPath,
            subTabsConfig: createStatusManagementSubTabsConfig({
                isLoadingDetails,
                entityDetails,
                accessControl,
                isInReadOnlyMode,
                parentActiveMainTabSegmentURL: statusManagementRouteSegmentPath,
                memberStatusActionChecks,
                t,
            }),
        }]
        : []
    ),

    ...(accessControl.hasPermission(PN.TOPUP.GET_ALL_CARDS)
        ? [{
            icon: <CreditCardOutlined />,
            label: t('common:tabsNames.topUpCards'),
            hasList: true,
            activeMainTabSegmentURL: topupCardsRouteSegmentPath,
            content: <TopupCardsList memberId={fixedId} />,
        }] : []),
    ...(accessControl.hasPermission(PN.CONFIG.MEMBER_MARKETING.GET_ALL)
        ? [
            {
                icon: <ShopOutlined />,
                label: t('common:tabsNames.marketingPreferences'),
                activeMainTabSegmentURL: marketingPreferencesRouteSegmentPath,
                hasList: true,
                content: <MarketingPreferencesList memberId={fixedId} />,
            },
        ] : []),
];

export default { createMemberComplianceSubTabsConfig };
